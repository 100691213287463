import React from "react";
import PageTemplate from "../components/page-template";
import Hero from "../components/hero";
// import AboutUs from "../components/about-us";

const WhatWeDo = () => {
  return (
    <PageTemplate title="About Us | Innovate Web Solutions">
        <Hero size="small" color="primary" content={
            <div>
                <h1 className="title color-primary">Apps - Coming Soon</h1>
                {/* <p>Mobile apps often offer a great amount of benifits to the companies who choose to have them. By having your own app, you allow your business to be in the pocket of Thousands of smartphone users</p> */}
            
            </div>
        }/>

        {/* <section>
        test
        </section> */}
    </PageTemplate>
  );
};

export default WhatWeDo;